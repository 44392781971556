<!--This contains the visual elements for adding a Product. Analogous to AddLos.vue-->

<template>
    <form @submit="onSubmit" class="edit-form">
<div class="form-control">
    <label>Produktbezeichnung</label>
    <input type="text" v-model="text" name="text" placeholder="Produktbezeichnung hinzufügen" />
  </div>
  <div class="form-control">
    <label>Hinweise für Bieter*innen zu diesem Los (sofern erforderlich)</label>
    <input
      type="text"
      v-model="hinweis"
      name="hinweis"
      placeholder="Hinweis hinzufügen"
    />
  </div>
  <div class="form-control">
    <label>Nummerierung laut Leistungsverzeichnis</label>
    <input
      type="text"
      v-model="nummer"
      name="nummer"
      placeholder="Typ und Zahl hinzufügen (möglichst kurz, z.B. Produkt 1, Los 1)"
    />
  </div>
  <div class="form-control">
    <label>Optional: Gewichtung</label>
    <input
      type="number"
      step="0.01"
      v-model="gewichtung"
      name="gewichtung"
      placeholder="Optional: Prozentzahl hinzufügen (z.B. Lose höher/niedriger gewichten je nach Einkaufsvolumen)"
    />
  </div>

  <input type="submit" value="Änderungen übernehmen" class="btn btn-block" style="background: #BDB9B4"/>
</form>
</template>

<script>
export default {
    name: 'EditLos',
    props: {
        los: Object
    },
    data(){
        return {
            id: this.los.id,
            text: this.los.produktbezeichnung,
            hinweis: this.los.besondere_hinweise,
            nummer: this.los.nummer,
            gewichtung: this.los.gewichtung
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie eine Produktbezeichnung an.')
                return
            }
            const newLos = {
                id: this.id,
                text: this.text,
                hinweis: this.hinweis,
                nummer: this.nummer,
                ziffer: this.los.ziffer,
                gewichtung: this.gewichtung
            }



            this.$emit('edit-los', newLos)
        }
    }
}
</script>

<style scoped>
.edit-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>