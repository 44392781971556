<!--
    This is confusingly named. It is the header for the edit view. Realy there is no reason for
    this to be encapsulated like this. When refactoriing consider moving the functional code
    inline.
-->

<template>
    <header>
        <h1>Ausschreibung: {{title}}</h1>
        <Button @button-clicked="$emit('exit-mode')"
        :text= "buttonText"
        color= '#F2F1F0' />
    </header>
</template>

<script>
import Button from '../Button'


export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            default: 'Hello World',
        },
        buttonText: {
            type: String,
            default: 'Zurück zur Übersicht (Export)',
        },
        showAddElement: false
    },
    components: {
            Button
        },
}
</script>


<style scoped>
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
</style>