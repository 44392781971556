<!--This handles the visual representation of a single Question as well as the Certificates attached-->

<template>
    <div
    :class="['frage']"
    style="background: #F2F1F0"
    >
    <!--Show Question Text and Edit Icons-->
        <h4>
          <h3>Frage {{fragebogen_ziffer}}.{{frage.ziffer}}: {{frage.titel}} </h3>
          <h3>
              <i class="tooltip">
                <span class="tooltiptext">
                  Hoch Bewegen
                </span>
                <font-awesome-icon @click="$emit('move-frage-up')" icon="fa-solid fa-chevron-up "></font-awesome-icon>
              </i>&nbsp;
              <i class="tooltip">
                <span class="tooltiptext">
                  Runter bewegen
                </span>
                <font-awesome-icon @click="$emit('move-frage-down')" icon="fa-solid fa-chevron-down "></font-awesome-icon>
              </i>&nbsp;
              <i class="tooltip">
                <span class="tooltiptext">
                  Editieren
                </span>
                <font-awesome-icon @click="showEditFrage = !showEditFrage" icon="fa-solid fa-pen-to-square "></font-awesome-icon>
              </i>&nbsp;
              <i class="fas tooltip">
                <span class="tooltiptext">
                  Löschen
                </span>
                <font-awesome-icon @click="$emit('delete-frage')" icon="fa-solid fa-trash ">
                </font-awesome-icon>
              </i>
          </h3>
        </h4>
        <EditFrage v-show = "showEditFrage" @edit-frage="$emit('edit-frage',$event); showEditFrage=false" :frage="frage"></EditFrage>

        <p style="white-space: pre-line;">{{frage.text}}</p>
        <h4>Punkte: {{new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 3 }).format(frage.punkte)}}</h4>
        <br/>

        <!--If there are No certificates of any kind atached show a warning-->
        <p 
          v-show = "zertifikate.filter(z=>z.frage === frage.id).length === 0 && alternativNachweise.filter(z=>z.frage === frage.id).length === 0"
          style="color: red;">
          Bitte fügen Sie dieser Frage einen oder mehrere Nachweise hinzu (Gütezeichen <u>und/oder</u> alternative Nachweise).
        </p>

        <!--Show all certificates-->
        <header>
          <h4>Gütezeichen (Zertifikate/Mitgliedschaften):</h4>
           <i class="tooltip">
            <span class="tooltiptext">
              Neues Gütezeichen
            </span>
            <font-awesome-icon @click="showAddZertifikat = !showAddZertifikat" icon="fa-solid fa-plus "></font-awesome-icon>
          </i>
        </header>
        <AddZertifikat v-show = "showAddZertifikat" :id="frage.id" @add-Zertifikat="addZertifikat"/>
        <div 
        :key = "zertifikat.id" 
        v-for="zertifikat in zertifikate.filter(z=>z.frage === frage.id)"
        >  
          <header style="background: #CAC7C3; padding-left: 10px; padding-right: 10px;">
            <p style="white-space: pre-line;">{{zertifikat.text}}</p>
            <h3>
              <i class="tooltip">
                <span class="tooltiptext">
                  Gütezeichen editieren
                </span>
                <font-awesome-icon @click="showEditZertifikat === zertifikat.id ? showEditZertifikat = false : showEditZertifikat = zertifikat.id" icon="fa-solid fa-pen-to-square">
                </font-awesome-icon>
              </i>&nbsp;
              <i class="tooltip">
                <span class="tooltiptext">
                  Gütezeichen löschen
                </span>
                <font-awesome-icon @click="$emit('delete-zertifikat', zertifikat.id)" icon="fa-solid fa-minus ">
                </font-awesome-icon>
              </i>
            </h3>
          </header>
          <EditZertifikat 
            v-show = "showEditZertifikat === zertifikat.id" 
            @edit-zertifikat="$emit('edit-zertifikat',$event); showEditZertifikat=false" 
            :zertifikat="zertifikat">
          </EditZertifikat>

        </div>

        <br/>

        <!--Show all alternate certificates-->
        <header>
          <h4>Alternative Nachweise (keine Gütezeichen):</h4>
           <i class="tooltip">
            <span class="tooltiptext">
              Neuer Nachweis
            </span>
            <font-awesome-icon @click="(showAddNachweis = !showAddNachweis)" icon="fa-solid fa-plus ">
            </font-awesome-icon>
          </i>
        </header>
        <AddAltZertifikat v-show = "showAddNachweis" :id="frage.id" @add-Zertifikat="addNachweis"/>
        <div :key = "nachweis.id" v-for="nachweis in alternativNachweise.filter(z=>z.frage === frage.id)">
          <header style="background: #CAC7C3; padding-left: 10px; padding-right: 10px;">
            <p style="white-space: pre-line;">{{nachweis.text}}</p>
            <h3>
              <i class="tooltip">
                <span class="tooltiptext">
                  Nachweis editieren
                </span>
                <font-awesome-icon @click="showEditAltZertifikat === nachweis.id ? showEditAltZertifikat = false : showEditAltZertifikat = nachweis.id" icon="fa-solid fa-pen-to-square ">
                </font-awesome-icon>
              </i>&nbsp;
              <i class="tooltip">
                <span class="tooltiptext">
                  Nachweis löschen
                </span>
                <font-awesome-icon @click="$emit('delete-nachweis', nachweis.id)" icon="fa-solid fa-minus ">
                </font-awesome-icon>
              </i>
            </h3>
          </header>
          <EditAltZertifikat 
            v-show = "showEditAltZertifikat === nachweis.id" @edit-zertifikat="$emit('edit-nachweis',$event);
            showEditAltZertifikat=false" 
            :zertifikat="nachweis">
          </EditAltZertifikat>

        </div>
    </div>
    
</template>

<script>
import AddZertifikat from './AddZertifikat';
import EditZertifikat from './EditZertifikat';
import EditAltZertifikat from './EditAltZertifikat';
import AddAltZertifikat from './AddAltZertifikat';
import EditFrage from './EditFrage';

export default {
    name: 'Frage',
    components: {
      AddZertifikat,
      AddAltZertifikat,
      EditFrage,
      EditZertifikat,
      EditAltZertifikat
    },
 
    props: {
        frage: Object,
        edit: false,
        fragebogen_ziffer: Number,
        zertifikate: Array,
        alternativNachweise: Array,
        showEditFrage: false,
        showEditZertifikat: false,
        showEditAltZertifikat: false
    },
    data() {
      return{
        showAddZertifikat: false,
        showAddNachweis: false
      }
    },
    methods: {
      addZertifikat(zertifikat)
      {
        this.showAddZertifikat = false;

        this.$emit('add-Zertifikat', zertifikat);
      },
      addNachweis(nachweis)
      {
        this.showAddNachweis = false;

        this.$emit('add-nachweis', nachweis);
      }
    },
    emits: ['edit-zertifikat', 'delete-frage', 'delete-zertifikat', 'add-Zertifikat', 'add-nachweis', 'move-frage-up', 'move-frage-down', 'edit-frage']
}
</script>

<style scope>
.fas {
  color: rgb(201, 0, 0);
}
.frage {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.frage.reminder {
  border-left: 5px solid green;
}
.frage h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.frage h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.frage header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
}
</style>
