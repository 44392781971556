<!--
    Analogous to Questionaires.vue
-->

<template>
    <div :key="questionaireTemplate.id" v-for="questionaireTemplate in questionaireTemplates">
        <QuestionaireTemplate 
        @duplicate-questionaire-template="$emit('duplicate-questionaire-template', $event)"
        :questionaireTemplate = "questionaireTemplate"/>
    </div>
</template>

<script>
import QuestionaireTemplate from './QuestionaireTemplate'

export default {
    name: 'QuestionaireTemlates',
    props: {
        questionaireTemplates: Array
    },
    components:{
        QuestionaireTemplate
    },
    emits: ['duplicate-questionaire-template']
}
</script>