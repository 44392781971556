<!--
    Analogous to Questionaire.vue
-->

<template>
    <div 
    :class="['questionaireTemplate']"
    style="background: #F2F1F0"
    >
        <h3>{{questionaireTemplate.titel}}
          <h4>
             <i class="tooltip"><span class="tooltiptext">Kopie erstellen</span><font-awesome-icon @click="$emit('duplicate-questionaire-template', questionaireTemplate)" icon="fa-solid fa-copy"></font-awesome-icon></i>
            </h4>
        </h3>
        <p>{{questionaireTemplate.kommentar}}</p>
    </div>
    
</template>

<script>
import { thisTypeAnnotation } from '@babel/types';

export default {
    name: 'QuestionaireTemplate',
    props: {
        questionaireTemplate: Object
    },
}
</script>

<style scope>
.fas {
  color: rgb(201, 0, 0);
}
.questionaireTemplate {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.questionaireTemplate.reminder {
  border-left: 5px solid green;
}
.questionaireTemplate h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.questionaireTemplate h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.tooltip {
  position: relative;
  display: inline-block;
  font-size: medium;
}

.tooltip .tooltiptext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>