<template>
    <form @submit="onSubmit" class="edit-form">
<div class="form-control">
    <label>Titel des Fragebogens</label>
    <input type="text" v-model="text" name="text" placeholder='Titel hinzufügen (z.B. "Fragenkatalog unternehmerische
Sorgfaltspflichten in der Konfektion")' />
  </div>
  <div class="form-control">
    <label>Produktionsstufe</label>
    <input
      type="text"
      v-model="produktionsstufe"
      name="produktionsstufe"
      placeholder="Produktionsstufe Hinzufügen"
    />
  </div>
  <!--<div class="form-control form-control-check">
    <label>Set Reminder</label>
    <input type="checkbox" v-model="reminder" name="reminder" />
  </div>-->

  <input type="submit" value="Änderungen übernehmen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'EditFragebogen',
    props:
    {
        fragebogen: Object
    },
    data(){
        return {
            text: this.fragebogen.text,
            produktionsstufe: this.fragebogen.produktionsstufe
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie einee Bezeichnung an.')
                return
            }
            const newFragebogen = {
                id: this.fragebogen.id,
                ziffer: this.fragebogen.ziffer,
                text: this.text,
                produktionsstufe: this.produktionsstufe
            }



            this.$emit('edit-fragebogen', newFragebogen)
        }
    }
}
</script>

<style scoped>
.edit-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>