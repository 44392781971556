<!--This contains the visual elements for editing the title of a Questionaire. Caslled from Editieren.vue-->

<template>
    <form @submit="onSubmit" class="edit-form">
<div class="form-control">
    <label>Ausschreibungstitel: </label>
    <input type="text" v-model="titel" name="titel" placeholder="Ausschreibungstitel" />

</div>
<div class="form-control">
    <label>Kommentar</label>
    <input
      type="text"
      v-model="kommentar"
      name="kommentar"
      placeholder="Kommentar hinzufügen (z.B. aktueller Stand, nächste Schritte, Fristen, o.ä.)"
    />
  </div>

  <input type="submit" value="Änderungen übernehmen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'EditFrage',
    props: {
        titel: "",
        kommentar:""
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.titel){
                alert('Bitte geben sie einen Titel an.')
                return
            }
            const newTitel = {
                titel: this.titel,
                kommentar: this.kommentar
            }

            this.$emit('edit-ausschreibungstitel', newTitel)
        }
    }
}
</script>

<style scoped>
.edit-form {
margin-bottom: 40px;
}\sql\sql_count_avg_sum.asp
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>