import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
library.add(far)

createApp(App).use(Router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
