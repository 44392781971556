<!--
    Section header with a Button that changes color when selected.
-->

<template>
    <header>
        <h1>{{title}}</h1>
        <Button @button-clicked="$emit('toggle-add')"
        :text="showAddElement ? 'Verbergen' : buttonText"
        :color="showAddElement? '#A39D97' : '#F2F1F0'"
        :textcolor="showAddElement? '#c1110f' : '#4B75AC'" />
    </header>
</template>

<script>
import Button from './Button'


export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            default: 'Hello World',
        },
        buttonText: {
            type: String,
            default: 'Neu'
        },
        showAddElement: false
    },
    components: {
            Button
        },
}
</script>


<style scoped>
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
</style>