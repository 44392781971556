import {createRouter, createWebHistory} from 'vue-router'

import Ausschreibungen from '../views/Ausschreibungen'
import Editieren from '../views/Editieren'
import Fallback from '../views/Fallback'

const routes = [
    {
        path: '/:user',
        name: 'Ausschreibungen',
        component: Ausschreibungen
    },
    {
        path: '/editieren/:user/:id',
        name: 'Editieren',
        component: Editieren
    },
    {
        path: '/:catchAll(.*)',
        name: 'Fallback',
        component: Fallback
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router