<!--
    This handles the visual representation of a list of products.
    It passes the summ of all product weights on to each product to allow for warnings in case of missing weights.
-->

<template>
    <div :key="los.id" v-for="(los, index) in lose">
        <Los
            @move-los-up="$emit('move-los-up', los.id)"
            @move-los-down="$emit('move-los-down', los.id)"
            @delete-los="$emit('delete-los', los.id)"
            @edit-los="$emit('edit-los', $event)"
            @toggle-produktionsstufe="$emit('toggle-produktionsstufe',$event)"
            :los = "los"
            :produktionsstufen = "produktionsstufen"
            :frageboegen = "frageboegen"
            :summeGewichtungen = "summeGewichtungen"
            :losNr="(index + 1)"
        />
    </div>
</template>

<script>
import Los from './Los'

export default {
    name: 'Lose',
    props: {
        lose: Array,
        produktionsstufen: Array,
        frageboegen: Array
    },
    components:{
        Los
    },
    computed:{
        summeGewichtungen()
        {
            let ret = 0;
            this.lose.forEach(element => {
                ret += element.gewichtung;
            });
            return ret;
        }
    },
    emits: ['delete-los', 'edit-los', 'toggle-produktionsstufe','move-los-up','move-los-down'],
    created()
    {
    }
}
</script>