<!--This contains the visual elements for adding a question. Called from Fragen.vue-->

<template>
    <form @submit="onSubmit" class="add-form">
<div class="form-control">
    <label>Fragentitel: </label>
    <input type="text" v-model="titel" name="titel" placeholder="Kurzen Titel hinzufügen: Worum geht es in dieser Frage? (z.B. Existenzsichernde Löhne, Risikomanagement)" />

    <label>Fragentext: </label>
    <textarea v-model="text" name="text" placeholder="Ausformulierten Fragentext hinzufügen" />

    <label>Punkte: </label>
    <input type="number" step="0.1" v-model="punkte" name="punkte" placeholder="Zahl hinzufügen: Mögliche Punktzahl in der Wertung für diese Frage" />
  </div>
  <!--<div class="form-control form-control-check">
    <label>Set Reminder</label>
    <input type="checkbox" v-model="reminder" name="reminder" />
  </div>-->

  <input type="submit" value="Anlegen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'AddFrage',
    data(){
        return {
            titel: '',
            text: '',
            punkte: ''
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.titel){
                alert('Bitte geben sie einen Titel an.')
                return
            }
            if(!this.text){
                alert('Bitte geben sie einen Fragentext an.')
                return
            }
            if(!this.punkte){
                alert('Bitte geben sie eine Punktzahl an.')
                return
            }
            const newFrage = {
                titel: this.titel,
                text: this.text,
                punkte: this.punkte
            }

            this.$emit('add-frage', newFrage)
            this.titel = ''
            this.text = ''
            this.punkte = ''
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>