<!--This contains the visual elements for adding a product. Called from Editieren.vue-->

<template>
    <form @submit="onSubmit" class="add-form">
<div class="form-control">
    <label>Produktbezeichnung</label>
    <input type="text" v-model="text" name="text" placeholder="Produktbezeichnung hinzufügen" />
  </div>
  <div class="form-control">
    <label>Hinweise für Bieter*innen zu diesem Los (sofern erforderlich)</label>
    <input
      type="text"
      v-model="hinweis"
      name="hinweis"
      placeholder="Hinweis hinzufügen"
    />
  </div>
  <div class="form-control">
    <label>Nummerierung laut Leistungsverzeichnis</label>
    <input
      type="text"
      v-model="nummer"
      name="nummer"
      placeholder="Typ und Zahl hinzufügen (möglichst kurz, z.B. Produkt 1, Los 1)"
    />
  </div>
  <div class="form-control">
    <label>Optional: Gewichtung</label>
    <input
      type="number"
      step="0.01"
      v-model="gewichtung"
      name="gewichtung"
      placeholder="Optional: Prozentzahl hinzufügen (z.B. Lose höher/niedriger gewichten je nach Einkaufsvolumen)"
    />
  </div>

  <input type="submit" value="Anlegen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'AddLos',
    data(){
        return {
            text: '',
            hinweis: '',
            nummer: '',
            gewichtung: null
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie eine Produktbezeichnung an.')
                return
            }
            const newLos = {
                text: this.text,
                hinweis: this.hinweis,
                nummer: this.nummer,
                gewichtung: this.gewichtung
            }



            this.$emit('add-los', newLos)
            this.text = ''
            this.hinweis = ''
            this.nummer = ''
            this.gewichtung = null
            
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>