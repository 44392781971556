<!--
    This handles the visual representation of a product as well as removing or editing said product.
-->

<template>
    <div
    :class="['los']"
    style="background: #F2F1F0"
    >
        <h3><h3>{{los.nummer}}: {{los.produktbezeichnung}}</h3>
          <h4>
            <i class="tooltip">
              <span class="tooltiptext">Hoch Bewegen</span>
              <font-awesome-icon @click="$emit('move-los-up')" icon="fa-solid fa-chevron-up"></font-awesome-icon>
            </i>
            &nbsp;
            <i class="tooltip">
              <span class="tooltiptext">Runter bewegen</span>
              <font-awesome-icon @click="$emit('move-los-down')" icon="fa-solid fa-chevron-down"></font-awesome-icon>
            </i>
            &nbsp;
            <i class="tooltip">
              <span class="tooltiptext">Editieren</span>
              <font-awesome-icon @click="showEditLos = !showEditLos" icon="fa-solid fa-pen-to-square"></font-awesome-icon>
            </i>
            &nbsp;
            <i class="fas tooltip">
              <span class="tooltiptext">Löschen</span>
              <font-awesome-icon @click="$emit('delete-los', los.id)" icon=" fa-solid fa-trash"></font-awesome-icon>
            </i>
          </h4>
        </h3>

        <EditLos 
          v-show="showEditLos" 
          @edit-los= "$emit('edit-los', $event); 
          showEditLos = false;" 
          :los="los">
        </EditLos>

        <!-- Note that gewichtungText and gewichtungWarning are computed values. See below.-->
        <p><b>Besondere Hinweise: </b>{{los.besondere_hinweise}}</p>
        <p><b>Gewichtung: </b>{{gewichtungText}} <b style="color:red"> {{gewichtungWarning}}</b></p>

        <div :key="fragebogen.id" v-for="fragebogen in frageboegen">
          <div @click = "$emit('toggle-produktionsstufe', {los: los.id, fragebogen: fragebogen.id})">
            <p v-show = "!(produktionsstufen.find(f => f.fragebogen == fragebogen.id && f.los == los.id) == null)">
              <font-awesome-icon icon="fa-regular fa-square-check left-aligned"></font-awesome-icon> 
              Fragebogen {{fragebogen.ziffer}}: {{fragebogen.produktionsstufe}}
            </p>
            <p v-show = "(produktionsstufen.find(f => f.fragebogen == fragebogen.id && f.los == los.id) == null)">
              <font-awesome-icon icon="fa-regular fa-square left-aligned"></font-awesome-icon>
              Fragebogen {{fragebogen.ziffer}}: {{fragebogen.produktionsstufe}}
            </p>
          </div>
        </div>
    </div>
    
</template>

<script>
import { thisTypeAnnotation } from '@babel/types';
import EditLos from './EditLos';

export default {
    name: 'Los',
    components: {
      EditLos
    },
    props: {
        los: Object,
        produktionsstufen: Array,
        frageboegen:Array,
        losNr: Number,
        showEditLos: false,
        summeGewichtungen: Number
    },
    computed:{
      gewichtungText()
      {
        if(this.los.gewichtung == 0)
        {
          if(this.summeGewichtungen == 0)
            {
              return "keine Gewichtung";
            }
        }
        else
        {
          return "" + new Intl.NumberFormat('de-DE', { maximumSignificantDigits:4 }).format(this.los.gewichtung) + "%";
        }
        return "";
      },
      gewichtungWarning()
      {
        if(this.los.gewichtung == 0)
        {
          if(this.summeGewichtungen != 0)
            {
              return " fehlende Gewichtung";
            }
        }
        return "";
      }
    },
}
</script>

<style scope>
.fas {
  color: rgb(201, 0, 0);
}
.los {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.los h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.los h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i.left-aligned{
  display: inline;
  padding-right: 10px;
}
</style>