let appConfig = {
  apiRoot: ""
};

const API_ROOT = "http://localhost:80";

if (process.env.NODE_ENV === "development") {
  appConfig = {
    ...appConfig,
    apiRoot: API_ROOT,

  };
}

export default Object.freeze(appConfig);