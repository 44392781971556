<!--
    This handles the visual representation of the list of certificates.
-->


<template>
    <div v-if="frageboegen&&fragen&&zertifikate">
        <div :key="zertifikatText" v-for="zertifikatText in new Set(zertifikate.map(a => a.text))">
            <div class="zertifikat">
                <header>
                    <h2 style="white-space: pre-line;">{{zertifikatText}}</h2>
                    <i class="tooltip">
                        <span class="tooltiptext">Zertifikat Entfernen</span>
                        <font-awesome-icon @click="$emit('delete-zertifikat', zertifikatText)" icon="fa-solid fa-minus "></font-awesome-icon>
                    </i>
                </header>
                <b>Fragen:</b>&nbsp;
                <a :key="zertifikat.id" v-for="zertifikat in zertifikate.filter(f=>f.text ===zertifikatText)"> 
                    {{getFragebogenZiffer(zertifikat)}}.{{getFrageZiffer(zertifikat)}}&nbsp;
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Zertifikate',
    props: {
        zertifikate: null,
        fragen: null,
        frageboegen: null
    },
    components:{
    },
    methods:{
        getFragebogenZiffer(zertifikat)
        {
            let frage = this.fragen.find(z=>z.id === zertifikat.frage);
            if(frage)
            {
                let fId = frage.fragebogen_id;
                let fragebogen = this.frageboegen.find(fb=> fb.id === (fId))
                if(fragebogen) return (fragebogen).ziffer;
            }   
        },
        getFrageZiffer(zertifikat)
        {
            let frage= this.fragen.find(z=>z.id === zertifikat.frage);
            if(frage) return frage.ziffer
        }
    },
    emits: ['delete-zertifikat', 'edit-zertifikat']
}
</script>

<style scope>
    .zertifikat {
        background: #f4f4f4;
        margin: 5px;
        padding: 10px 20px;
        cursor: pointer;
    }
</style>