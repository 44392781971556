<!--
    This is the first view a user should see.
    
    Note that references to Downloaded some files not yeet set.
-->

<template>

    
     <i class="tooltip" style="float: right">
        <span class="tooltiptext">Anleitung</span>
        <font-awesome-icon @click="(showInfo =!showInfo)" icon="fa-solid fa-circle-info"></font-awesome-icon>
    </i>
    <br/>
    <Header 
        @toggle-add="showAddQuestionaire = !showAddQuestionaire" 
        :title = "adminView?'Vorlagen für Nachweisfragebögen':'Ausschreibungen mit Nachweisfragebögen'" 
        buttonText = "Neue Ausschreibung anlegen" 
        :showAddElement = "showAddQuestionaire"
     />
    
    
    <div v-show="showInfo && !adminView" class="container" style="background: #CAE7DA">
        <b>Willkommen im Nachweisfragebogen-Generator des Kompass Nachhaltigkeit!</b><br/><br/>
        <p>Dieses Tool unterstützt Sie bei der Strukturierung Ihrer Nachhaltigkeitskriterien. Kurze Tipps und
        Hinweise können Sie über die <b>Infosymbole</b> <font-awesome-icon icon="fa-solid fa-circle-info"/> oben rechts in jedem Abschnitt ein- und ausblenden.
        Ausführlichere Informationen finden Sie in der <a href="https://tools.kompass-nachhaltigkeit.de/anleitungen/Anleitung%20-%20Nachweisfragebogen-Generator%202023.pdf" target="_blank" rel="noopener"><b>Anleitung</b> (PDF)</a>.</p><br/>
        <p>Verfügen Sie bereits über einen Nachweisfragebogen, in dem Gütezeichen und alternative
        Nachweise definiert wurden? Dann können Sie diese Daten mithilfe des Tools jetzt digitalisieren.
        Alternativ können Sie eine Vorlage importieren und an Ihr Verfahren anpassen.</p><br/>
        <p> Die Hauptübersicht bietet folgende Funktionen:</p><br/>
        <ul style="margin-left: 40px">
        <li><b>Neues Projekt anlegen</b> oder Nachweisfragebögen als Vorlage importieren</li>
        <li>Vorhandenes <b>Projekt bearbeiten</b> <font-awesome-icon icon="fa-solid fa-pen-to-square"/></li>
        <li>Vorhandenes <b>Projekt duplizieren</b> <font-awesome-icon icon="fa-solid fa-copy"/>, d.h. eine Kopie davon erstellen, z.B. eine alte
        Ausschreibung aktualisieren oder Änderungen testen, ohne die alte Version zu
        überschreiben.</li>
        <li><b>Fragebögen exportieren (Word)</b> <font-awesome-icon icon="fa-solid fa-file-word"/> als vollständige Übersicht über alle Fragen, Nachweise
        und Wertungspunkte. Nutzen Sie diese als Prüfansicht vor dem Excel-Export. Ergänzungen
        (z.B. Erläuterungen zu neuen Anforderungen) sind danach über Textprogramme möglich.</li>
        <li><b>Fragebögen exportieren (Excel)</b> <font-awesome-icon icon="fa-solid fa-file-excel"/> als Tabelle zum Ausfüllen durch die bietenden
        Unternehmen. Die ausgefüllten Dateien können Sie später für die Angebotswertung nutzen.
        Alle Fragebögen in einem Projekt werden in einer Tabelle zusammengefasst.</li>
        <li>Ein vorhandenes <b>Projekt löschen</b> <font-awesome-icon class="fas" icon="fa-solid fa-trash"/></li>
        </ul><br/>
        <p><b>Wichtig:</b> Beide hier erstellten Dateien (Word + Excel) zum Nachweisfragebogen, sowie das
        <a href="/anleitungen/Nachweisfragebogen-Informationsblatt-fuer-Bietende.docx" target="_blank" rel="noopener">Informationsblatt für Bietende</a> (Word-Dokument) sollten Sie <b>mit den Ausschreibungsunterlagen
        veröffentlichen.</b></p>
        <p>Es wird empfohlen, kursiv gedruckte Textstellen im Informationsblatt individuell an Ihre Ausschreibung anzupassen (für Tipps siehe Anleitung).</p>
        <br/>
        <p>Bestimmte Sonderzeichen können im Tool aus technischen Gründen nicht verwendet werden (z.B.
        Backslash, einfache und doppelte Anführungszeichen, Semikolon u.a.).</p><br/>
        <b>Viel Erfolg bei Ihrer Ausschreibung mit Nachhaltigkeitskriterien!</b>
    </div>

    <div v-show="showInfo && adminView" class="container" style="background: #CAE7DA">
        <b>Willkommen im Nachweisfragebogen-Generator des Kompass Nachhaltigkeit!</b><br/><br/>
        <p>Sie befinden sich in der Ansicht zur Verwaltung der Vorlagen.</p><br/>
        <p>Die Funktionen zum Anlegen von Nachweisfragebögen sind identisch mit der normalen
        Nutzer*innennansicht. Kurze Tipps und Hinweise können Sie über die <b>Infosymbole </b>
        <font-awesome-icon icon="fa-solid fa-circle-info"/> oben rechts in
        jedem Abschnitt ein- und ausblenden. Ausführlichere Informationen finden Sie in der 
        <a href="https://tools.kompass-nachhaltigkeit.de/anleitungen/Anleitung%20-%20Nachweisfragebogen-Generator%202023.pdf" target="_blank" rel="noopener"><b>Anleitung</b> (PDF)</a>.</p><br/>
        <p> Jede Ausschreibung in dieser Übersicht kann als Vorlage veröffentlicht werden:
        Das Auge zeigt an, ob eine Vorlage für Nutzer*innen <b>sichtbar</b> <font-awesome-icon icon="fa-solid fa-eye"/>
         oder <b>unsichtbar</b> <font-awesome-icon icon="fa-solid fa-eye-slash"/> ist. Per Klick können Sie eine Vorlage
        <b>veröffentlichen</b> <font-awesome-icon icon="fa-solid fa-eye-slash"/> oder eine 
        Vorlage <b>verbergen</b> <font-awesome-icon icon="fa-solid fa-eye"/>.
        </p><br/>
        <p><b>Ein neues Projekt ist zunächst unsichtbar.</b> Bitte beachten Sie, dass der Arbeitstitel ebenfalls Teil der
        Vorlage sein wird. Alle Änderungen werden automatisch gespeichert (und bei sichtbaren Projekten
        ggf. sofort veröffentlicht). Sobald ein Projekt sichtbar ist, können alle Nutzer*innen des Tools diese
        Projekte als Vorlagen importieren.</p><br/>
        <p>Zum <b>Anpassen einer Vorlage </b> <font-awesome-icon icon="fa-solid fa-pen-to-square"/> sollten Sie das 
            Projekt zunächst <b>duplizieren </b> <font-awesome-icon icon="fa-solid fa-copy"/>. Erst wenn die
        Änderungen in der Kopie abgeschlossen sind, sollten Sie die alte Version <b>löschen </b>
        <font-awesome-icon class="fas" icon="fa-solid fa-trash"/> oder
        <b>verbergen </b> <font-awesome-icon icon="fa-solid fa-eye"/>
        und die neue Version <b>veröffentlichen </b> <font-awesome-icon icon="fa-solid fa-eye-slash"/>.</p><br/>
        <p><b>Vorlagen aus dem Vorlagenpool</b>, die Sie selbst nicht verwalten, können nur über das zugehörige
        Konto geändert werden. Sie können eine <b>Kopie erstellen</b> <font-awesome-icon icon="fa-solid fa-copy"/>, um Inhalte einzusehen oder daraus eine
        neue Vorlage zu erstellen. Wollen Sie eine Vorlage in Ihr Konto verschieben, wenden Sie sich bitte an
        den Systemadministrator.</p><br/>
        <b>Bestimmte Sonderzeichen können im Tool aus technischen Gründen nicht verwendet werden (z.B.
        Backslash, einfache und doppelte Anführungszeichen, Semikolon u.a.).</b>
    </div>

    <div v-show="showAddQuestionaire">
      <AddQuestionaire @add-questionaire='addQuestionaire'/>
    </div>
    <p>
        Zur Veröffentlichung mit den Ausschreibungsunterlagen: Download 
        <a href="/anleitungen/Nachweisfragebogen-Informationsblatt-fuer-Bietende.docx" target="_blank" rel="noopener">Informationsblatt für Bietende</a> (Word-Dokument) mit 
        Verfahrenshinweisen und Tipps zum Ausfüllen der interaktiven Tabelle
    </p>
    <Questionaires
    @delete-Questionaire="deleteQuestionaire"
    @edit-questionaire="editQuestionaire"
    @export-to-excel="exportToExcel"
    @export-to-word="exportToWord"
    @duplicate-questionaire="duplicateQuestionaire"
    @publish-questionaire="publishQuestionaire"
    @unpublish-questionaire="unpublishQuestionaire"
    :questionaires = "questionaires"
    :admin="adminView" />

    <br/><br/>

    <Header 
        @toggle-add="showAddFromTemplate = !showAddFromTemplate; showAddFromTemplate? volagenTitel='Vorlagen' : volagenTitel =''"
        :title = "volagenTitel"  
        :buttonText = "adminView?'Alle Vorlagen anzeigen':'Neue Ausschreibung aus Vorlage'" 
        :showAddElement = "showAddFromTemplate" 
    />
    <p v-show="showAddFromTemplate">
        Die Nachweisfragebögen in den Vorlagen sind praxiserprobt. 
        Überprüfen Sie die Nachhaltigkeitskriterien, ob Sie zu Ihrer 
        geplanten Vergabe passen und ergänzen oder löschen Sie Inhalte entsprechend.
    </p>
    <div v-show="showAddFromTemplate">
        <QuestionaireTemplates
        @duplicate-questionaire-template="copyTemplate"
        :questionaireTemplates = "templates" />
    </div>

    <Header v-show="false" @toggle-add="destroySession" title = "" buttonText = "Destroy Session" :showAddElement = "false" />
    

</template>

<script>
import Header from '../components/Header'
import Questionaires from '../components/Questionaires'
import QuestionaireTemplates from '../components/QuestionaireTemplates'
import AddQuestionaire from '../components/AddQuestionaire'
import axios from 'axios'
import appConfig from '../appConfig'
import { throwStatement } from '@babel/types'

export default {
    name: 'Ausschreibungen',
    props:{
        
    },
    components:{
        Questionaires,
        QuestionaireTemplates,
        AddQuestionaire,
        Header
    },
    data(){
        return{
            questionaires: [],
            templates: [],
            showInfo:true,
            showAddQuestionaire: false,
            showAddFromTemplate: false,
            adminView: false,
            volagenTitel: ""
        }
    },
    methods:{
        async exportToExcel(id)
        {
            let questionaire = this.questionaires.find(x => x.id==id);

            let fileURL = `${appConfig.apiRoot}/ausschreibungen/exportToExcel.php?id=` + encodeURIComponent(questionaire.id);
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.click();
        },
        async exportToWord(id)
        {
            let questionaire = this.questionaires.find(x => x.id==id);

            let fileURL = `${appConfig.apiRoot}/ausschreibungen/exportToWord.php?id=` + encodeURIComponent(questionaire.id);
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.click();
        },
        async publishQuestionaire(id){
            //this does nothing if the user ist not an admin
            if (confirm('Durch das Veröffentlichen ist diese Ausschreibung für alle Nutzer*innen als Vorlage sichtbar. Fortfahren?')) 
            {
                axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
                +'_ausschreibungen&id= ' +encodeURIComponent(id)
                +'&values= `public` = '+ true
                , {withCredentials: true})
                .then(response => (this.ok = response.data))
                .then(function() {
                    this.fetchQuestionaires();
                    this.fetchTemplates();
                }.bind(this));
            }

        },
        async unpublishQuestionaire(id){
            //this does nothing if the user ist not an admin
            if (confirm('Durch das Verbergen ist diese Ausschreibung nicht mehr für Nutzer*innen als Vorlage sichtbahr. Fortfahren?')) 
            {
                axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
                +'_ausschreibungen&id= ' +encodeURIComponent(id)
                +'&values= `public` = '+ false
                , {withCredentials: true})
                .then(response => (this.ok = response.data))
                .then(function() {
                    this.fetchQuestionaires();
                    this.fetchTemplates();
                }.bind(this));
            }
        },
        async addQuestionaire(questionaire){
            this.showAddQuestionaire = false;

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
                + '_ausschreibungen&columns=`id`, `titel`, `kommentar`, `public`' 
                + '&values=NULL, \''
                +encodeURIComponent(this.sanatizeString(questionaire.titel))
                +'\', \''+encodeURIComponent(this.sanatizeString(questionaire.kommentar))
                +'\', \'' + false 
                +'\'', {withCredentials: true} )
                .then(response => (this.ok = response.data))
                .then(function() {
                    this.fetchQuestionaires();
                }.bind(this));
            
                await this.setupTables(this.ok.id);
                
        },
        async duplicateQuestionaire(id){

            let questionaire = this.questionaires.find(element => (id == element.id));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
                + '_ausschreibungen&columns=`id`, `titel`, `kommentar`, `public`' 
                + '&values=NULL, \''+encodeURIComponent(questionaire.titel)
                +'\', \''+encodeURIComponent(questionaire.kommentar)
                +'\', 0' 
                +'&id=' 
                + encodeURIComponent(questionaire.id)
                , {withCredentials: true})
                .then(response => (this.ok = response.data))
                .then(function() {
                    this.fetchQuestionaires();
                }.bind(this));

            let table2Id = this.ok.id;

            await this.setupTables(table2Id);

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_fragen&table2=' + '_' + encodeURIComponent(table2Id) +'_fragen', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_lose&table2=' + '_' + encodeURIComponent(table2Id) +'_lose', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_zertifikate&table2=' + '_' + encodeURIComponent(table2Id) +'_zertifikate', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_frageboegen&table2=' + '_' + encodeURIComponent(table2Id) +'_frageboegen', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_alternativnachweise&table2=' + '_' + encodeURIComponent(table2Id) +'_alternativnachweise', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTable.php?table1=` + '_' + encodeURIComponent(id) +'_produktionsstufen&table2=' + '_' + encodeURIComponent(table2Id) +'_produktionsstufen', {withCredentials: true})
                .then(response => (this.ok = response.data));

        },
        async deleteQuestionaire(id){
            

            if (confirm('Ausschreibung unwiederruflich löschen?')) {
                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_fragen', {withCredentials: true})
                .then(response => (this.ok = response.data));

                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_lose', {withCredentials: true})
                    .then(response => (this.ok = response.data));

                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_zertifikate', {withCredentials: true})
                    .then(response => (this.ok = response.data));

                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_frageboegen', {withCredentials: true})
                    .then(response => (this.ok = response.data));

                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_alternativnachweise', {withCredentials: true})
                    .then(response => (this.ok = response.data));

                await axios.get(`${appConfig.apiRoot}/ausschreibungen/deleteTable.php?table=` + '_' + encodeURIComponent(id) +'_produktionsstufen', {withCredentials: true})
                    .then(response => (this.ok = response.data));

                axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=` + '_ausschreibungen&id=' +encodeURIComponent(id), {withCredentials: true})
                    .then(response => (this.ok = response.data))
                    .then(function() {
                        this.fetchQuestionaires();
                    }.bind(this));
            }
        },
        async setupTables(id){
            //this does nothing if the tables allready exist

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_fragen&vars= id INT AUTO_INCREMENT PRIMARY KEY, fragebogen_id INT, titel TEXT, text TEXT, ziffer INT, punkte FLOAT', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_lose&vars= id INT AUTO_INCREMENT PRIMARY KEY, produktbezeichnung TEXT, besondere_hinweise TEXT, nummer TEXT, ziffer INT, gewichtung FLOAT', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_zertifikate&vars= id INT AUTO_INCREMENT PRIMARY KEY, frage INT, text TEXT', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_alternativnachweise&vars= id INT AUTO_INCREMENT PRIMARY KEY, frage INT, text TEXT', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_frageboegen&vars= id INT AUTO_INCREMENT PRIMARY KEY, ziffer INT, text TEXT, produktionsstufe TEXT', {withCredentials: true})
                .then(response => (this.ok = response.data));

            await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=` + '_' + encodeURIComponent(id) +'_produktionsstufen&vars= id INT AUTO_INCREMENT PRIMARY KEY, fragebogen TEXT, los TEXT', {withCredentials: true})
                .then(response => (this.ok = response.data));
        },
        async editQuestionaire(id){
            await this.setupTables(id);

            this.$router.push(`/editieren/${this.$route.params.user}/${id}`);
        },
        async fetchQuestionaires(){
            await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_ausschreibungen&sort=`id`', {withCredentials: true})
                .then(response => (this.questionaires = response.data));

            if(this.questionaires.length > 0)
            {
                this.showLongInfo = false;
            }
            else
            {
                this.showLongInfo = true;
            }
        },
        async fetchTemplates()
        {
            await axios.get(`${appConfig.apiRoot}/ausschreibungen/getTemplates.php` , {withCredentials: true})
                .then(response => (this.templates = response.data));
        },
        async copyTemplate(template)
        {
            await axios.get(`${appConfig.apiRoot}/ausschreibungen/copyTemplate.php?tableId=`
            + template.tableId
            +'&id='
            + template.id
            , {withCredentials: true})
                .then(response => (this.ok = response.data));

            console.log(this.ok);

            await this.fetchQuestionaires();
        },
        sanatizeString (str) {
            str = str.replace(/[^a-z0-9áéíóúñüäö?!()/ß \n\.,_-]/gim, " ");
            return str.trim();
        },
        async validateUser()
        {
            //this will return an object with two values:
            //valid: this can be "valid" or "invalid"
            //(this might be better as a bool, but It's usefull for passing debug information)
            //admin: bool
            let success = "";
            await axios.get(`${appConfig.apiRoot}/ausschreibungen/validateUser.php?user=`+ encodeURIComponent(this.$route.params.user), {withCredentials: true})
                .then(response => (success = response.data));

            return(success);
        },
        async destroySession()
        {
            //this is a debug function for testing sessions.
            await axios.get(`${appConfig.apiRoot}/ausschreibungen/destroySession.php`, {withCredentials: true})
                .then();
        }
    },
    async created() {
        //check if user is valid
        let userValidated = await this.validateUser();

        //go to landing page if user not valid
        console.log(userValidated);
        if(userValidated.valid !== "valid") this.$router.push(`/`);

        //show buttons for publishing templates if admin
        //(this does NOT give the user admin rights or allow them to publish templates)
        if(userValidated.admin) this.adminView = true;

        //if user is new setup base table
        await axios.get(`${appConfig.apiRoot}/ausschreibungen/setupTable.php?table=_ausschreibungen&vars= id INT AUTO_INCREMENT PRIMARY KEY, titel TEXT, kommentar TEXT, public BOOLEAN`, {withCredentials: true})
            .then(response => (this.ok = response.data));

        await this.fetchQuestionaires();

        await this.fetchTemplates();

        if(this.questionaires.length <=  0)
        {
            //this makes info visible if no questioneires exist.
            //currently this does nothing since the info text is always visible.
            //left in here so that we can decide to not show info text everytime. it's anoying. 
            this.showInfo = true;
        }
    }
}
</script>