<!--This handles the visual representation of a single Questionaire as well as the Icons for editing exporting etc.-->

<template>
    <div 
    :class="['questionaire']"
    style="background: #F2F1F0"
    >
        <h3>{{questionaire.titel}}
          <h4>
            <i class="tooltip">
              <span class="tooltiptext">Verbergen</span>
              <font-awesome-icon v-show="admin&&questionaire.public" @click="$emit('unpublish-questionaire')" icon="fa-solid fa-eye"></font-awesome-icon>
            </i>
            
            <i class="tooltip">
              <span class="tooltiptext">Veröffentlichen</span>
              <font-awesome-icon v-show="admin&&!questionaire.public" @click="$emit('publish-questionaire')" icon="fa-solid fa-eye-slash"></font-awesome-icon>
            </i>
            &nbsp;&nbsp;

            <i class="tooltip">
              <span class="tooltiptext">Editieren</span>
              <font-awesome-icon @click="$emit('edit-questionaire')" icon="fa-solid fa-pen-to-square" ></font-awesome-icon>
            </i>
            &nbsp;&nbsp;

            <i class="tooltip">
              <span class="tooltiptext">Duplizieren</span>
              <font-awesome-icon @click="$emit('duplicate-questionaire')" icon="fa-solid fa-copy"></font-awesome-icon>
            </i>
            &nbsp;&nbsp;

            <i class="tooltip">
              <span class="tooltiptext">Export Word</span>
              <font-awesome-icon @click="$emit('export-to-word')" icon="fa-solid fa-file-word"></font-awesome-icon>
            </i>
            &nbsp;&nbsp;

            <i class="tooltip">
              <span class="tooltiptext">Export Excel</span>
              <font-awesome-icon @click="$emit('export-to-excel')" icon="fa-solid fa-file-excel"></font-awesome-icon>
            </i>
            &nbsp;&nbsp;

            <i class="tooltip">
              <span class="tooltiptext">Löschen</span>
              <font-awesome-icon @click="$emit('delete-questionaire')" icon="fa-solid fa-trash" class="fas"></font-awesome-icon>
            </i>
          </h4>
        </h3>
        <p>{{questionaire.kommentar}}</p>
    </div>
    
</template>

<script>
import { thisTypeAnnotation } from '@babel/types';

export default {
    name: 'Questionaire',
    props: {
        questionaire: Object,
        admin: Boolean
    },
}
</script>

<style scope>
.fas {
  color: rgb(201, 0, 0);
}
.questionaire {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.questionaire.reminder {
  border-left: 5px solid green;
}
.questionaire h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.questionaire h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
  font-size: medium;
}

.tooltip .tooltiptext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>