<!--This contains the visual elements for editing a Certificate. Analogous to AddZertifikat.vue-->

<template>
    <form @submit="onSubmit" class="add-form">
    <div class="form-control">
        <label>Bezeichnung des Gütezeichens</label>
        <input type="text" v-model="text" name="text" placeholder="Bezeichnung hinzufügen (ggf. Hinweise ergänzen, z.B. falls ein besonders guter Status erreicht worden sein muss)" />
        <input type="submit" value="Änderungen übernehmen" class="btn btn-block" style="background: #BDB9B4"/>
    </div>

    
</form>
</template>

<script>
export default {
    name: 'EditZertifikat',
    props:
    {
        zertifikat: Object
    },
    data(){
        return {
            text: this.zertifikat.text
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie eine Bezeichnung an.')
                return
            }
            const newZertifikat = {
                id: this.zertifikat.id,
                text: this.text
            }

            this.$emit('edit-zertifikat', newZertifikat)
  
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>