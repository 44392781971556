<!--This contains the visual elements for adding a set of questions. Called from Editieren.vue-->

<template>
    <form @submit="onSubmit" class="add-form">

  <div class="form-control">
    <label>Produktionsstufe</label>
    <input
      type="text"
      v-model="produktionsstufe"
      name="produktionsstufe"
      placeholder="Für welche Stufe gilt dieser Fragebogen? (z.B. Fasern, Konfektion, gesamte Lieferkette)"
    />
  </div>

  <div class="form-control">
    <label>Titel des Fragebogens</label>
    <input type="text" v-model="text" name="text" placeholder='Um welchen Nachhaltigkeitsaspekt geht es? (formelle Überschrift, z.B. „Unternehmerische Sorgfaltspflichten in der Konfektion“)' />
  </div>

  <input type="submit" value="Anlegen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'AddFragebogen',
    data(){
        return {
            text: '',
            produktionsstufe: ''
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie einee Bezeichnung an.')
                return
            }
            const newFragebogen = {
                //id: Math.floor(Math.random() * 100000),
                text: this.text,
                produktionsstufe: this.produktionsstufe
            }


            this.$emit('add-fragebogen', newFragebogen)
            this.text = ''
            this.produktionsstufe = ''

            
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>