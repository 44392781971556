<!--
    This is our default landing page.
    All invalid urls redirect here.
    This includes invalid user credentials.
-->

<template>

    <div class="container" style="background: #CAE7DA">
        <h2 style="color: #4B75AC">Nachweisfragebogen-Generator in <i>Mein Kompass</i></h2>
        <br/>
<p><b>Als Hilfsmittel für die nachhaltige Beschaffungspraxis stellt Ihnen der Kompass Nachhaltigkeit
dieses Online-Tool zur Verfügung. Der Nachweisfragebogen-Generator unterstützt Sie, indem er
Ihre geforderten Kriterien strukturiert abbildet und Ihnen die Auswertung erleichtert.</b></p><br/>

<p>Den Login-Bereich Mein Kompass können alle Kommunen kostenfrei nutzen. Dort können Sie in
einem eigens angelegten Nutzerprofil wertvolle Unterstützungsmöglichkeiten rund um die
nachhaltige Beschaffung nutzen: Die eigene nachhaltige Beschaffungsstrategie abbilden und
verfolgen, Beschaffungsdaten auswerten, Fragen an Expert*innen stellen oder über dieses Tool
Nachweisfragebögen für bietende Unternehmen generieren.</p><br/>

<p>Hier können Sie sich Ihren Zugang anlegen: <a href="https://www.kompass-nachhaltigkeit.de/mein-kompass-
start">https://www.kompass-nachhaltigkeit.de/mein-kompass-
start</a></p><br/>

<p>Entwickelt wurde das Tool durch <u>FEMNET</u>. Dabei wurde der Verein nach einem gemeinsamen
<u>Pilotprojekt zur Beschaffung nachhaltiger Miettextilien</u> durch die Entsorgungsbetriebe und die
Technischen Betriebe der Stadt Konstanz und durch den Vergabejuristen André Siedenberg
unterstützt. Die technische Umsetzung erfolgte durch Michael Schorer. Gefördert wird das FEMNET-
Projekt „Beschaffung fairändern“ von Engagement Global mit ihrer Servicestelle Kommunen in der
Einen Welt (SKEW) im Auftrag des BMZ. Unterstützt wird es durch Fairtrade Deutschland.</p><br/>
    </div>
</template>