<!--This contains the visual elements for adding an alternative Certificate. Called from Frage.vue-->

<template>
    <form @submit="onSubmit" class="add-form">
    <div class="form-control">
        <label>Bezeichnung</label>
        <textarea v-model="text" name="text" placeholder="Gibt es für diese Frage einen Nachweis, der kein Gütezeichen ist?" />
        <input type="submit" value="Anlegen" class="btn btn-block" style="background: #BDB9B4"/>
    </div>


    
</form>
</template>

<script>
export default {
    name: 'AddAltZertifikat',
    props:
    {
        id: Number
    },
    data(){
        return {
            text: ''
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.text){
                alert('Bitte geben sie eine Bezeichnung an.')
                return
            }
            const newZertifikat = {
                id: this.id,
                text: this.text
            }



            this.$emit('add-zertifikat', newZertifikat)
            this.text = ''

            
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>