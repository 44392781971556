<!--This handles the visual representation of the list of Questionaires-->

<template>
    <div :key="questionaire.id" v-for="questionaire in questionaires">
        <Questionaire 
        @unpublish-questionaire="$emit('unpublish-questionaire', questionaire.id)"
        @publish-questionaire="$emit('publish-questionaire', questionaire.id)"
        @delete-questionaire="$emit('delete-questionaire', questionaire.id)"
        @edit-questionaire="$emit('edit-questionaire', questionaire.id)"
        @export-to-excel="$emit('export-to-excel', questionaire.id)"
        @export-to-word="$emit('export-to-word', questionaire.id)"
        @duplicate-questionaire="$emit('duplicate-questionaire', questionaire.id)"
        :questionaire = "questionaire"
        :admin="admin"/>
    </div>
</template>

<script>
import Questionaire from './Questionaire'

export default {
    name: 'Questionaires',
    props: {
        questionaires: Array,
        admin: Boolean
    },
    components:{
        Questionaire
    },
    emits: ['delete-questionaire', 'edit-questionaire', 'export-to-excel', 'export-to-word', 'duplicate-questionaire', 'unpublish-questionaire', 'publish-questionaire']
}
</script>