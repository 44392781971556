<!--This handles the visual representation of a set of questions-->

<template>
    <div :key="fragebogen.id" v-for="fragebogen in frageboegen">
        <div class="container" style="background: #CAC7C3">
            <header>
                <h1>Fragebogen {{fragebogen.ziffer}}</h1>
                <h3>
                    <i class="tooltip">
                        <span class="tooltiptext">
                            Editieren
                        </span>
                        <font-awesome-icon @click="showEditFragebogen === fragebogen.id ? showEditFragebogen = false : showEditFragebogen = fragebogen.id" icon="fa-solid fa-pen-to-square">
                        </font-awesome-icon>
                    </i>&nbsp;
                    <i class="tooltip">
                        <span class="tooltiptext">
                            Löschen
                        </span>
                        <font-awesome-icon @click="$emit('delete-fragebogen', fragebogen.id)" icon="fa-solid fa-trash " class="fas">
                        </font-awesome-icon>
                    </i>
                </h3>
            </header>

            <div v-show = "showEditFragebogen === fragebogen.id">
                <EditFragebogen @edit-fragebogen="$emit('edit-fragebogen', $event);showEditFragebogen = false;" :fragebogen="fragebogen"></EditFragebogen>
            </div>

            <p><b>Titel des Fragebogens: </b> {{fragebogen.text}}</p>

            <p><b>Produktionsstufe: </b> {{fragebogen.produktionsstufe}}</p>

            <Fragen
                @move-frage-up="$emit('move-frage-up', $event)"
                @move-frage-down="$emit('move-frage-down', $event)"
                @delete-frage="$emit('delete-frage', $event)"
                @edit-frage="$emit('edit-frage', $event)"
                @add-zertifikat="$emit('add-zertifikat',$event)"
                @delete-zertifikat="$emit('delete-zertifikat', $event)"
                @add-nachweis="$emit('add-nachweis', $event)"
                @delete-nachweis="$emit('delete-nachweis', $event)"
                @add-frage="$emit('add-frage', $event, fragebogen.id)"
                @edit-zertifikat="$emit('edit-zertifikat',$event)"
                @edit-nachweis="$emit('edit-nachweis',$event)"
                :fragen = "fragen" 
                :fragebogen = "fragebogen" 
                :zertifikate="zertifikate" 
                :alternativNachweise ="alternativNachweise"
            />

        </div>
    </div>
</template>

<script>
import Header from '../Header';
import Fragen from './Fragen';
import EditFragebogen from './EditFragebogen';


export default {
    name: 'Frageboegen',
    props: {
        frageboegen: Array,
        fragen: Array,
        showEditFragebogen: false,
        zertifikate: Array,
        alternativNachweise: Array
    },
    components:{
        Fragen,
        Header,
        EditFragebogen
    },
    emits: ['delete-frage', 'edit-frage', 'add-frage', 'add-zertifikat', 'delete-zertifikat', 'delete-fragebogen', 'add-nachweis','delete-nachweis','move-frage-up','move-frage-down', 'edit-fragebogen', 'edit-zertifikat', 'edit-nachweis']
}
</script>

<style scope>
.fas {
  color: rgb(201, 0, 0);
}
header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
}
</style>