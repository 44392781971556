<!--This contains the visual elements for adding a questionaire. Called from Ausschreibungen.vue-->

<template>
    <form @submit="onSubmit" class="add-form">
<div class="form-control">
    <label>Arbeitstitel</label>
    <input type="text" v-model="titel" name="titel" placeholder="Arbeitstitel hinzufügen (z.B. eingekaufte Produkte und Jahreszahl)" />
  </div>
  <div class="form-control">
    <label>Kommentar</label>
    <input
      type="text"
      v-model="kommentar"
      name="kommentar"
      placeholder="Kommentar hinzufügen (z.B. aktueller Stand, nächste Schritte, Fristen, o.ä.)"
    />
  </div>


  <input type="submit" value="Anlegen" class="btn btn-block" style="background: #F2F1F0"/>
</form>
</template>

<script>
export default {
    name: 'AddQuestionaire',
    data(){
        return {
            titel: '',
            kommentar: ''
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.titel){
                alert('Bitte geben sie eine Titel an.')
                return
            }
            const newQuestionaire = {
                titel: this.titel,
                kommentar: this.kommentar
            }



            this.$emit('add-questionaire', newQuestionaire)
            this.titel = ''
            this.kommentar = ''

            
        }
    }
}
</script>

<style scoped>
.add-form {
margin-bottom: 40px;
}
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>