<!--
  This view allows you to edit a Questionaire. most of the heavy lifting is done here.
-->

<template>
  <i class="tooltip" style="float:right">
    <span class="tooltiptext">Anleitung</span>
    <font-awesome-icon @click="(showPageInfo =!showPageInfo)" icon="fa-solid fa-circle-info "></font-awesome-icon>
  </i><br/>

  <EditHeader @exit-mode="$router.push(`/${this.$route.params.user}`)" :title= "ausschreibung.titel"></EditHeader>

  <div v-show="showPageInfo" class="container" style="background: #CAE7DA">
      
    Hier können Sie einen oder mehrere <b>Nachweisfragebögen für Ihre Ausschreibung</b> 
    anlegen oder anpassen. Das Tool speichert jede Eingabe automatisch. <br/><br/>
    Über die <b>Infosymbole</b> <font-awesome-icon icon="fa-solid fa-circle-info"/> 
    können Sie diese Hinweise jederzeit ein- und ausblenden. Ausführlichere Informationen 
    finden Sie in der <a href="">Anleitung (PDF)</a>. Sollte die Schrift zu groß oder zu 
    klein sein, passen Sie bitte die Ansicht (Zoom) in Ihrem Browser an.<br/><br/>
    Wir empfehlen folgenden Ablauf: <br/><br/>
    <ol style="margin-left: 40px">
    <li><b>Produkte/Lose anlegen:</b> Beachten Sie bei der Einteilung, welche Nachweise wo 
      gelten sollen. </li>
    <li><b>Fragebögen erstellen:</b> Erstellen Sie für einzelne Produktionsstufen eigene 
      Fragebögen. Geben Sie für jede Frage Nachweise (Gütezeichen und/oder alternative 
      Nachweise) und Wertungspunkte an. </li>
    <li><b>Zuordnung der Fragebögen prüfen:</b> Sie können entscheiden, ob die Fragebögen 
      für alle oder nur für einzelne Produkte/Lose gelten sollen. Bitte beachten Sie, dass 
      bei unterschiedlichen Punktzahlen eine Gewichtung zwischen den Produkten/Losen entstehen 
      kann.</li>
    <li><b>Verwendete Zertifikate/Mitgliedschaften prüfen:</b> Nutzen Sie die untenstehende 
      Ansicht, um Ihre Gütezeichen auf Vollständigkeit und etwaige Fehler zu prüfen. </li>
    <li><b>Fragebögen exportieren (Word & Excel):</b> Kehren Sie dafür zur Hauptübersicht zurück. </li>
    </ol><br/><br/>
    So funktionieren die Buttons: <br/><br/>
    <ul style="margin-left: 40px">
      <li><b>Infosymbol</b> <font-awesome-icon icon="fa-solid fa-circle-info"/>, um Tipps und Hinweis ein- oder auszublenden</li>
      <li><b>Bearbeiten</b> <font-awesome-icon icon="fa-solid fa-pen-to-square"/>, um Bezeichnungen u.a. Angaben anzupassen</li>
      <li><b>Löschen <font-awesome-icon class="fas" icon="fa-solid fa-trash"/></b>, um ein Element zu entfernen</li>
      <li><b>Nach oben</b> <font-awesome-icon icon="fa-solid fa-chevron-up"/> oder 
        <b>nach unten <font-awesome-icon icon="fa-solid fa-chevron-down"/> verschieben</b>, um die Reihenfolge von Elementen anzupassen</li>
      <li><b>Plus</b> <font-awesome-icon icon="fa-solid fa-plus"/>, um einen Nachweis hinzuzufügen </li>
      <li><b>Minus</b> <font-awesome-icon icon="fa-solid fa-minus"/>, um einen Nachweis zu entfernen </li>
    </ul><br/>
    Bestimmte Sonderzeichen können im Tool aus technischen Gründen nicht verwendet werden (z.B. 
    Backslash, einfache und doppelte Anführungszeichen, Semikolon u.a.).

  </div>

  <!--
    Section for showing/editing Questionwire name, commentary
  -->
  <div class="container" style="background: #A1D6BD">

    <p>
      <b>Arbeitstitel:</b> {{ausschreibung.titel}} 
      <i class="tooltip" style="float:right">
        <span class="tooltiptext">Editieren</span>
        <font-awesome-icon @click="(showEditAusschreibungTitel =!showEditAusschreibungTitel)" icon="fa-solid fa-pen-to-square "></font-awesome-icon>
      </i><br/>
    </p>
    <p><b>Kommentar:</b> {{ausschreibung.kommentar}}</p>

  </div>

  <div v-show="showEditAusschreibungTitel">
    <EditAusschreibungTitel @edit-ausschreibungstitel="renameAusschreibung" :titel="ausschreibung.titel" :kommentar="ausschreibung.kommentar"/>
  </div>

  <!--
    Section for Showing products
  -->
  <div class="container" style="background: #A1D6BD">
      <i class="tooltip" style="float:right">
      <span class="tooltiptext">Anleitung</span>
      <font-awesome-icon @click="(showLosInfo =!showLosInfo)" icon="fa-solid fa-circle-info "></font-awesome-icon>
    </i><br/>
    <Header @toggle-add="showAddLos = !showAddLos" title = "Produkte/Lose" buttonText="Neues Produkt/Los anlegen" :showAddElement = "showAddLos" />
    
    <div v-show="showLosInfo" class="container" style="background: #CAE7DA">
      <p>Stellen Sie sicher, dass <b>innerhalb einer Produktgruppe die gleichen Nachweise</b>
        möglichst für alle Produkte gültig sind. Andernfalls teilen Sie diese in verschiedene
        Produktgruppen/Lose auf oder vermerken Sie Ausnahmen als Besonderen Hinweis.</p><br/>

      <p>Um die spätere Tabelle schlank zu halten, sollten Sie <b>nur die relevanten
        Produkte/Lose</b> im Tool erfassen, für welche die Nachhaltigkeitskriterien in
        den Fragebögen gelten sollen. Die <b>Reihenfolge</b> können Sie über die Pfeile anpassen.</p><br/>

      <p>Optional können Sie eine <b>Gewichtung</b> festlegen (z.B. nach Beschaffungsvolumen
        der einzelnen Produktgruppen). Wird keine Gewichtung angegeben, zählen die Wertungspunkte einfach. </p><br/>

      <p><b>Zuordnung der Fragebögen prüfen:</b> Sobald Sie Fragebögen angelegt haben,
      können Sie pro Produktgruppe ankreuzen, welche dafür gelten sollen (standardmäßig
      sind alle Fragebögen angewählt). Bitte beachten Sie, dass Sie auch durch die Festlegung
      unterschiedlich hoher Punktzahlen eine Gewichtung zwischen den Produktgruppen schaffen können. </p><br/>
      Details zu den Möglichkeiten zur <b>Gewichtung</b> finden Sie in der <a href="">Anleitung (PDF)</a>.
    </div>
    
    <div v-show="showAddLos">
      <AddLos @add-los="addLos"/>
    </div>
    
    
    <Lose @delete-los="deleteLos"
      @move-los-up="moveLosUp"
      @move-los-down="moveLosDown"
      @edit-los="editLos" 
      @toggle-produktionsstufe="toggleProduktionsstufe" 
      :lose="lose" 
      :frageboegen = "frageboegen" 
      :produktionsstufen="produktionsstufen">
    </Lose>
  </div>

  <!--
    Section for Showing Question Sets.
  -->
  <div class="container" style="background: #A1D6BD">
    <i class="tooltip" style="float:right">
      <span class="tooltiptext">Anleitung</span>
      <font-awesome-icon @click="(showFragebogenInfo =!showFragebogenInfo)" icon="fa-solid fa-circle-info "></font-awesome-icon>
    </i><br/>
    <Header @toggle-add="showAddFragebogen = !showAddFragebogen" title = "Fragebögen" buttonText="Neuen Fragebogen anlegen" :showAddElement = "showAddFragebogen" />
    
    <div v-show="showFragebogenInfo" class="container" style="background: #CAE7DA">
      <p>Übersichtlicher wird die Nachweisführung, wenn Sie für <b>verschiedene Produktionsstufen</b> 
        (z.B. Rohstoffgewinnung, Herstellung/Montage) oder für verschiedene Nachweiserbringer 
        (i.d.R. das Herstellerunternehmen, alternativ z.B. ein Serviceunternehmen) <b>eigene Fragebögen</b> anlegen. </p><br/>

      <p>Legen Sie eine Frage an und ergänzen Sie dann die Nachweise. Unterscheiden Sie dabei zwischen 
        <b>Gütezeichen (Zertifikate oder Mitgliedschaften in Multistakeholderinitiativen)</b> und/oder <b>alternativen 
        Nachweisformen</b>. Wir empfehlen die Nutzung einer möglichst aktuellen Fragebogen-Vorlage. Im Kompass 
        Nachhaltigkeit finden Sie Informationen zu 
        <a href="https://www.kompass-nachhaltigkeit.de/produktsuche/oft-gesucht">Gütezeichen für Ihren Produktbereich</a>
          und zu <a href="https://www.kompass-nachhaltigkeit.de/nachweise">alternativen Nachweisen</a>.</p><br/>

      <p>Bitte achten Sie schon beim Anlegen der Nachweise auf die gewünschte Reihenfolge. Ändern Sie die 
        Reihenfolge, indem Sie Elemente löschen und neu anlegen.</p><br/>

    </div>

    <div v-show="showAddFragebogen">
      <AddFragebogen @add-fragebogen="addFragebogen"/>
    </div>
    <Frageboegen @add-frage = "addFrage"
      @move-frage-up = "moveFrageUp"
      @move-frage-down = "moveFrageDown"
      @delete-frage = "deleteFrage" 
      @add-zertifikat="addZertifikat"
      @delete-zertifikat="deleteZertifikat"
      @add-nachweis="addNachweis"
      @delete-nachweis="deleteNachweis"
      @delete-fragebogen="deleteFragebogen"
      @edit-frage="editFrage"
      @edit-fragebogen="editFragebogen"
      @edit-zertifikat="editZertifikat"
      @edit-nachweis="editNachweis"
      :frageboegen="frageboegen" 
      :fragen="fragen"
      :zertifikate ="zertifikate"
      :alternativNachweise="alternativNachweise"></Frageboegen>
  </div>

  <!--
    Section for Showing certificates
  -->
  <div class="container" style="background: #A1D6BD">
      <i class="tooltip" style="float:right">
      <span class="tooltiptext">Anleitung</span>
      <font-awesome-icon @click="(showZertifikateInfo =!showZertifikateInfo)" icon="fa-solid fa-circle-info "></font-awesome-icon>
    </i><br/>
    
    <h1>Verwendete Zertifikate/Mitgliedschaften</h1>

    <div v-show="showZertifikateInfo" class="container" style="background: #CAE7DA">
      <p>Dies ist eine <b>Prüfansicht</b>: Sind alle gewünschten Gütezeichen vorhanden? Gibt es Tippfehler? </p><br/>

      <p>Eventuelle Fehler können Sie oben in der entsprechenden Frage (siehe Fragenummer) korrigieren.</p><br/>
      <p>Soll ein Gütezeichen aus <u>allen</u> obenstehenden Fragen gelöscht werden, klicken in diesem Abschnitt 
      auf das Minus am entsprechenden Nachweis.  </p>
    </div>

    <Zertifikate @delete-zertifikat="deleteZertifikate" :zertifikate="zertifikate" :fragen="fragen" :frageboegen="frageboegen"></Zertifikate>

  </div>
</template>

<script>
import Header from '../components/Header'
import EditHeader from '../components/editMode/EditHeader'
import Lose from '../components/editMode/Lose'
import Fragen from '../components/editMode/Fragen'
import Frageboegen from '../components/editMode/Frageboegen'
import Zertifikate from '../components/editMode/Zertifikate'
import AddFragebogen from '../components/editMode/AddFragebogen'
import AddLos from '../components/editMode/AddLos'
import EditAusschreibungTitel from '../components/editMode/EditAusschreibungTitel'
import axios from 'axios'
import appConfig from '../appConfig'

export default {
  name: 'Editieren',
  components: {
    Header,
    EditHeader,
    Lose,
    Fragen,
    Frageboegen,
    Zertifikate,
    AddFragebogen,
    AddLos,
    EditAusschreibungTitel
  },
  data(){
    return{
      ausschreibung: Object,
      ausschreibungen: [],
      lose: [],
      fragen: [],
      frageboegen: [],
      zertifikate: [],
      alternativNachweise: [],
      showAddFragebogen: false,
      showAddLos: false,
      produktionsstufen: [],
      showPageInfo: true,
      showLosInfo: false,
      showFragebogenInfo: false,
      showZertifikateInfo: false,
      showEditAusschreibungTitel: false
      }
  },
  methods: {
    async fetchData() 
    {
        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_ausschreibungen&sort=`id`', {withCredentials: true})
          .then(response => (this.ausschreibungen = response.data));

        this.ausschreibungen.forEach(element => {
          if(element.id == this.$route.params.id )
          {
            this.ausschreibung = element
          }
        });

        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_lose&sort=`ziffer`', {withCredentials: true})
          .then(response => (this.lose = response.data));

        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_frageboegen&sort=`ziffer`', {withCredentials: true})
          .then(response => (this.frageboegen = response.data));
        
        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_fragen&sort=`ziffer`', {withCredentials: true})
          .then(response => (this.fragen = response.data));

        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_zertifikate&sort=`id`', {withCredentials: true})
          .then(response => (this.zertifikate = response.data));

        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_alternativnachweise&sort=`id`', {withCredentials: true})
          .then(response => (this.alternativNachweise = response.data));

        await axios.get(`${appConfig.apiRoot}/ausschreibungen/get.php?table=`+'_'+encodeURIComponent(this.$route.params.id)+'_produktionsstufen&sort=`id`', {withCredentials: true})
          .then(response => (this.produktionsstufen = response.data));
    },

    

    //////////////////////////////////
    //Zertifikat interface functions//
    //////////////////////////////////

    async addZertifikat(zertifikat){
      axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_zertifikate&columns=`id`, `frage`, `text`' 
        + '&values=NULL, \''
        +encodeURIComponent(zertifikat.id)
        +'\', \''+encodeURIComponent(this.sanatizeString(zertifikat.text)) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async editZertifikat(zertifikat){
      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_zertifikate&id= ' +encodeURIComponent(zertifikat.id)
        +'&values= `text` = \''+encodeURIComponent(this.sanatizeString(zertifikat.text))
        +'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async deleteZertifikat(id){
      if (confirm('Zertifikat Löschen?')) {

        axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_zertifikate&id=' +encodeURIComponent(id)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
      }
    },
    async quietDeleteZertifikat(id){
      //this exists to allow a confirmation on delete but not repeated confirmation requests for the same delete action.
      axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
      +'_'+encodeURIComponent(this.$route.params.id)
      +'_zertifikate&id=' +encodeURIComponent(id)
      , {withCredentials: true})
      .then(response => (this.ok = response.data))
      .then(function() {
        this.fetchData();
      }.bind(this));
    },
    async deleteZertifikate(text)
    {
      if (confirm('Zertifikat für alle betroffenen Fragen löschen?')) {
        this.zertifikate.forEach(element=>{
          if(element.text == text)
          {
            this.quietDeleteZertifikat(element.id)
          }
        })
      }
    },

    ////////////////////////////////
    //Nachweis interface functions//
    ////////////////////////////////

    async addNachweis(nachweis){
      axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_alternativnachweise&columns=`id`, `frage`, `text`' 
        + '&values=NULL, \''+encodeURIComponent(nachweis.id)
        +'\', \''
        +encodeURIComponent(this.sanatizeString(nachweis.text)) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async editNachweis(nachweis){
      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_alternativnachweise&id= ' +encodeURIComponent(nachweis.id)
        +'&values= `text` = \''+encodeURIComponent(this.sanatizeString(nachweis.text))
        +'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async deleteNachweis(id){
      if (confirm('Nachweis Löschen?')) {
        this.quietDeleteNachweis(id);
      }
    },
    async quietDeleteNachweis(id){
      //this exists to allow a confirmation on delete but not repeated confirmation requests for the same delete action.
      axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
      +'_'+encodeURIComponent(this.$route.params.id)
      +'_alternativnachweise&id=' +encodeURIComponent(id)
      , {withCredentials: true})
      .then(response => (this.ok = response.data))
      .then(function() {
        this.fetchData();
      }.bind(this));
    },
    async deleteNachweise(text)
    {
      if (confirm('Nachweis für alle betroffenen Fragen löschen?')) {
        this.alternativNachweise.forEach(element=>{
          if(element.text == text)
          {
            this.quietDeleteNachweis(element.id)
          }
        })
      }
    },

    ///////////////////////////
    //Los interface functions//
    ///////////////////////////

    async addLos(los){
      this.showAddLos = false; 

      await axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
      +'_'+encodeURIComponent(this.$route.params.id)
        +'_lose&columns=`id`, `produktbezeichnung`, `besondere_hinweise`, `nummer`, `ziffer`, `gewichtung`' 
        + '&values=NULL, \''
        +encodeURIComponent(this.sanatizeString(los.text))
        +'\', \''+encodeURIComponent(this.sanatizeString(los.hinweis)) 
        +'\', \''+encodeURIComponent(this.sanatizeString(los.nummer)) 
        +'\', \''+encodeURIComponent(this.lose.length) 
        +'\', \''+encodeURIComponent(los.gewichtung) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));

        let id = this.ok.id;

        //needs to be applied to all frageboegen
        this.frageboegen.forEach((fb) =>
        {
          axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
            +'_'+encodeURIComponent(this.$route.params.id)
            +'_produktionsstufen&columns=`fragebogen`, `los`' 
            +'&values=\''
            +encodeURIComponent(fb.id)
            +'\', \''
            +encodeURIComponent(id) 
            +'\'' 
            , {withCredentials: true})
            .then(response => (this.ok = response.data))
            .then(function() {
              this.fetchData();
            }.bind(this));
        })
    },
    async deleteLos(id){
      if (confirm('Produkt/Los löschen?')) {
        let los = this.lose.find(x => x.id==id);
        this.lose.forEach(element => {
          if(element.ziffer > los.ziffer)
          {
              this.setLosZiffer(element.id, (element.ziffer - 1));
          }
        });
        
        axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_lose&id=' +encodeURIComponent(id)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
      
        //needs to be removed from all produktionsstufen
        this.produktionsstufen.forEach((p) =>
          {
            if(p.los == id)
            {
              axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
                +'_'+encodeURIComponent(this.$route.params.id)
                +'_produktionsstufen&id=' +encodeURIComponent(p.id)
                , {withCredentials: true})
                .then(response => (this.ok = response.data))
                .then(function() {
                  this.fetchData();
                }.bind(this));
            }
          })
      }
      
    },
    async setLosZiffer(id, ziffer)
    {
      axios.get(`${appConfig.apiRoot}/ausschreibungen/setZiffer.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_lose&id=' +encodeURIComponent(id)
        +'&ziffer=' +encodeURIComponent(ziffer)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async moveLosUp(id)
    {
      let los = this.lose.find(element => (element.id == id));
      if(los.ziffer > 0)
      {
        let anderesLos = this.lose.find(element => (element.ziffer == (los.ziffer - 1)));
        if(anderesLos != null)
        {
          this.setLosZiffer(los.id, anderesLos.ziffer);
          this.setLosZiffer(anderesLos.id, los.ziffer);
        }
      } 
    },
    async moveLosDown(id)
    {
      let los = this.lose.find(element => (element.id == id));
      let anderesLos = this.lose.find(element => (element.ziffer == (los.ziffer + 1)));
        
      if(anderesLos != null)
      {
        this.setLosZiffer(los.id, anderesLos.ziffer);
        this.setLosZiffer(anderesLos.id, los.ziffer);
      }
    },
    async editLos(los){
      console.log(los);
      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_lose&id= ' +encodeURIComponent(los.id)
        +'&values= `produktbezeichnung` = \''+encodeURIComponent(this.sanatizeString(los.text))
        +'\', `besondere_hinweise` = \''+encodeURIComponent(this.sanatizeString(los.hinweis))
        +'\', `nummer` = \''+encodeURIComponent(this.sanatizeString(los.nummer))
        +'\', `ziffer` = \''+encodeURIComponent(los.ziffer)
        +'\', `gewichtung` = \''+encodeURIComponent(los.gewichtung)
        +'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
        console.log(los);
    },

    async toggleProduktionsstufe(losFragebogen)
    {
      let found = this.produktionsstufen.find(f => f.fragebogen == losFragebogen.fragebogen && f.los == losFragebogen.los);

      if(found != null)
      {
        axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_produktionsstufen&id=' +encodeURIComponent(found.id)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
      }
      else
      {
        axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_produktionsstufen&columns=`id`, `fragebogen`, `los`' 
        + '&values=NULL, \''+encodeURIComponent(losFragebogen.fragebogen)
        +'\', \''
        +encodeURIComponent(losFragebogen.los) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
      }
    },

    /////////////////////////////
    //Frage interface functions//
    /////////////////////////////

    async addFrage(frage, id){

      console.log(frage);
      console.log(id);

      let fragenCounter = 1;

      this.fragen.forEach(element => {
        if(element.fragebogen_id == id)
        {
          fragenCounter = fragenCounter + 1;
        }
      });

      axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_fragen&columns=`id`, `fragebogen_id`, `titel`, `text`, `ziffer`, `punkte`' 
        + '&values=NULL, \''
        +encodeURIComponent(id)
        +'\', \''
        +encodeURIComponent(this.sanatizeString(frage.titel))
        +'\', \''
        +encodeURIComponent(this.sanatizeString(frage.text)) 
        +'\', \''
        +encodeURIComponent(fragenCounter) 
        +'\', \''+encodeURIComponent(frage.punkte) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async deleteFrage(id){
      if (confirm('Frage Löschen?')) {
        
       this.quietDeleteFrage(id);
      }
    },
    async quietDeleteFrage(id){
      //this exists to allow a confirmation on delete but not repeated confirmation requests for the same delete action.
      let frage = this.fragen.find(x => x.id==id);
      this.fragen.forEach(element => {
        if(element.fragebogen_id ==frage.fragebogen_id)
        {
          if(element.ziffer > frage.ziffer)
          {
              this.setFragenZiffer(element.id, (element.ziffer - 1));
          }
        }
      });

      this.zertifikate.forEach(element => {
        if(element.frage == id)
        {
          this.quietDeleteZertifikat(element.id);
        }
      });

      this.alternativNachweise.forEach(element => {
        if(element.frage == id)
        {
          this.quietDeleteNachweis(element.id);
        }
      });


      axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
      +'_'+encodeURIComponent(this.$route.params.id)
      +'_fragen&id=' +encodeURIComponent(id)
      , {withCredentials: true})
      .then(response => (this.ok = response.data))
      .then(function() {
        this.fetchData();
      }.bind(this));
    },
    async setFragenZiffer(id, ziffer)
    {
      axios.get(`${appConfig.apiRoot}/ausschreibungen/setZiffer.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_fragen&id=' +encodeURIComponent(id)
        +'&ziffer=' +encodeURIComponent(ziffer)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
    async moveFrageUp(id)
    {
      let frage = this.fragen.find(element => (element.id == id));
      if(frage.ziffer > 1)
      {
        let andereFrage = this.fragen.find(element => (element.ziffer == (frage.ziffer - 1) && element.fragebogen_id == frage.fragebogen_id));
        if(andereFrage != null)
        {
          await this.setFragenZiffer(frage.id, andereFrage.ziffer);
          await this.setFragenZiffer(andereFrage.id, frage.ziffer);
        }
      } 
    },
    async moveFrageDown(id)
    {
      let frage = this.fragen.find(element => (element.id == id));
      let andereFrage = this.fragen.find(element => (element.ziffer == (frage.ziffer + 1) && element.fragebogen_id == frage.fragebogen_id));
        
      if(andereFrage != null)
      {
        await this.setFragenZiffer(frage.id, andereFrage.ziffer);
        await this.setFragenZiffer(andereFrage.id, frage.ziffer);
      }
    },

    async editFrage(frage){

      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_fragen&id= ' +encodeURIComponent(frage.id)
        +'&values= `titel` = \''+encodeURIComponent(this.sanatizeString(frage.titel))
        +'\', `text` = \''+encodeURIComponent(this.sanatizeString(frage.text)) 
        +'\', `punkte` = \''+encodeURIComponent(frage.punkte) +'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },

    //////////////////////////////////
    //Fragebogen interface functions//
    //////////////////////////////////

    async addFragebogen(fragebogen){

      this.showAddFragebogen = false;

      await axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_frageboegen&columns=`id`, `ziffer`, `text` , `produktionsstufe`' 
        + '&values=NULL, \'' 
        + encodeURIComponent(this.frageboegen.length + 1) 
        +'\', \''
        + encodeURIComponent(this.sanatizeString(fragebogen.text)) 
        + '\', \''
        + encodeURIComponent(this.sanatizeString(fragebogen.produktionsstufe)) 
        +'\'' 
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));

        let id = this.ok.id;

        this.lose.forEach((l) =>
        {
          axios.get(`${appConfig.apiRoot}/ausschreibungen/add.php?table=`
            +'_'+encodeURIComponent(this.$route.params.id)
            +'_produktionsstufen&columns=`fragebogen`, `los`' 
            +'&values=\''
            +encodeURIComponent(id)
            +'\', \''+encodeURIComponent(l.id) 
            +'\'' 
            , {withCredentials: true})
            .then(response => (this.ok = response.data))
            .then(function() {
              this.fetchData();
            }.bind(this));
            console.log(l.id);
        })
    },
    async deleteFragebogen(id){
      if (confirm('Fragebogen Löschen?')) {
        
        let fragebogen = this.frageboegen.find(x => x.id==id);
        this.frageboegen.forEach(element => {
          if(element.ziffer > fragebogen.ziffer)
          {
              this.setFragebogenZiffer(element.id, (element.ziffer - 1));
          }
        });

        this.fragen.forEach(element=>{
          if(element.fragebogen_id === fragebogen.id)
          {
            this.quietDeleteFrage(element.id);
          }
        })

        axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_frageboegen&id=' +encodeURIComponent(id)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));

        this.produktionsstufen.forEach((p) =>
        {
          if(p.fragebogen == id)
          {
            axios.get(`${appConfig.apiRoot}/ausschreibungen/delete.php?table=`
              +'_'+encodeURIComponent(this.$route.params.id)
              +'_produktionsstufen&id=' +encodeURIComponent(p.id)
              , {withCredentials: true})
              .then(response => (this.ok = response.data))
              .then(function() {
                this.fetchData();
              }.bind(this));
          }
        })
      }
    },
    async setFragebogenZiffer(id, ziffer)
    {
      axios.get(`${appConfig.apiRoot}/ausschreibungen/setZiffer.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_frageboegen&id=' +encodeURIComponent(id)
        +'&ziffer=' +encodeURIComponent(ziffer)
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },

    async editFragebogen(fragebogen){

      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_'+encodeURIComponent(this.$route.params.id)
        +'_frageboegen&id= ' +encodeURIComponent(fragebogen.id)
        +'&values= `ziffer` = \''+encodeURIComponent(fragebogen.ziffer)
        +'\', `text` = \''+encodeURIComponent(this.sanatizeString(fragebogen.text)) 
        +'\', `produktionsstufe` = \''+encodeURIComponent(this.sanatizeString(fragebogen.produktionsstufe)) 
        +'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },

    /////////////////////////////////////
    //Ausschreibung Interface Functions//
    /////////////////////////////////////
    async renameAusschreibung(titel)
    {
      console.log(titel);
      this.showEditAusschreibungTitel = false;
      
      axios.get(`${appConfig.apiRoot}/ausschreibungen/update.php?table=`
        +'_ausschreibungen&id= ' +encodeURIComponent(this.ausschreibung.id)
        +'&values= `titel` = \''+encodeURIComponent(titel.titel)+'\', `kommentar` = \''+encodeURIComponent(titel.kommentar)+'\''
        , {withCredentials: true})
        .then(response => (this.ok = response.data))
        .then(function() {
          this.fetchData();
        }.bind(this));
    },
   
    sanatizeString (str) {
        str = str.replace(/[^a-z0-9áéíóúñüäö?!()/ß \n\.,_-]/gim, " ");
        return str.trim();
    }
  },
  async created()
  {
    await this.fetchData();

    if(this.lose.length == 0) this.showLosInfo= true;

    if(this.frageboegen.length == 0) this.showFragebogenInfo = true;
    
    if(this.zertifikate.length == 0) this.showZertifikateInfo = true;

    //if(this.lose.length == 0 && this.frageboegen.length == 0 && this.zertifikate.length == 0) this.showPageInfo = true;
  }
}
</script>
