<!--
    This handles the visual representation of a list of questions as well as adding questions.
    This is a little inconsistent with how the rest of the code is structured.
    Consider merging into Frageboegen.vue
-->

<template>
    <Header @toggle-add="toggleAdd(fragebogen.id)" title ="Fragen:" buttonText="Neue Frage anlegen" :showAddElement = "showAddFrage === fragebogen.id" />
        <div :key="frage.id" v-for="frage in fragen.filter(f => f.fragebogen_id === fragebogen.id)">

            <Frage
                @move-frage-up="$emit('move-frage-up', frage.id)"
                @move-frage-down="$emit('move-frage-down', frage.id)"
                @delete-frage="$emit('delete-frage', frage.id)"
                @edit-frage="$emit('edit-frage', $event)"
                @add-zertifikat="$emit('add-zertifikat', $event)"
                @delete-zertifikat="$emit('delete-zertifikat', $event)"
                @add-nachweis="$emit('add-nachweis', $event)"
                @delete-nachweis="$emit('delete-nachweis', $event)"
                @edit-zertifikat="$emit('edit-zertifikat',$event)"
                @edit-nachweis="$emit('edit-nachweis',$event)"
                :frage = "frage"
                :zertifikate ="zertifikate"
                :alternativNachweise ="alternativNachweise"
                :fragebogen_ziffer ="fragebogen.ziffer"
            />

        </div>
    
    <Header 
        v-show="fragen.filter(f => f.fragebogen_id === fragebogen.id).length > 0" 
        @toggle-add="toggleAdd(fragebogen.id)" 
        title="" buttonText="Neue Frage anlegen" 
        :showAddElement = "showAddFrage === fragebogen.id"
    />

    <div v-bind:id="'addFrageDialogue' + fragebogen.id" >
        <AddFrage v-show = "showAddFrage === fragebogen.id" @add-frage="$emit('add-frage', $event); showAddFrage = false;"></AddFrage>
    </div>
</template>

<script>
import { interfaceDeclaration } from '@babel/types';
import Frage from './Frage'
import Header from '../Header';
import AddFrage from './AddFrage';

export default {
    name: 'Fragen',
    props: {
        fragen: Array,
        fragebogen: Object,
        zertifikate: Array,
        alternativNachweise: Array
    },
    components:{
        Frage,
        Header,
        AddFrage
    },
    data() {
        return{
            showAddFrage: false,
            secondButton: false
        }
    },
    methods:{
        toggleAdd(id)
        {
            this.showAddFrage === id ? this.showAddFrage = false : this.showAddFrage = id;
            if(this.showAddFrage != false)
            {
                document.getElementById("addFrageDialogue" + id).scrollIntoView({block: "center"});
            }
            
        }
    },
    emits: ['delete-frage', 'edit-frage', 'add-zertifikat', 'delete-zertifikat', 'add-nachweis','delete-nachweis','move-frage-up','move-frage-down', 'add-frage', 'edit-zertifikat', 'edit-nachweis']
}
</script>