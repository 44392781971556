<!--
  This Realy does nothing other than provide a cointainer for the router view to live in.
  Also sets some style stuff.
-->

<template>
  <div>
    <div class="container" >
      <router-view></router-view>
    </div>
  </div>
  <div style="text-align: right; padding: 10px 10px; font-size: 15px;"><a href="https://www.kompass-nachhaltigkeit.de/datenschutzerklaerung">Impressum</a></div>

</template>

<script>
import Header from './components/Header'


export default {
  name: 'App',
  components: {
    Header
  },
  data(){
    return{
      }
  },
  methods: {
  }
}
</script>

<style>
@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
   url(./fonts/SourceSansPro/SourceSansPro-Regular.ttf) format("truetype");
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}
.container {
  max-width: 3114px;
  background: #6cbe98;
  margin: 30px ;
  overflow: auto;
  min-height: 100px;
  
  padding: 30px;
  border-radius: 2px;
}
.btn {
  display: inline-block;
  background: #000;
  color: #4B75AC;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
::placeholder { 
  color: #CAC7C3;
  opacity: 1;
}
.btn:focus {
  outline: none;
}
.btn:active {
  transform: scale(0.98);
}
.btn-block {
  display: block;
  width: 100%;
}
</style>
