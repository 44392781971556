<!--This contains the visual elements for editing a question. Analogous to AddFragen.vue-->

<template>
    <form @submit="onSubmit" class="edit-form">
<div class="form-control">
    <label>Fragentitel: </label>
    <input type="text" v-model="titel" name="titel" placeholder="Fragentitel" />

    <label>Fragentext: </label>
    <textarea v-model="text" name="text" placeholder="Fragentext" />

    <label>Punkte: </label>
    <input type="number" step="0.1" v-model="punkte" name="punkte" placeholder="Anzahl der Punkte in der Bewertung" />
  </div>

  <input type="submit" value="Änderungen übernehmen" class="btn btn-block" style="background: #BDB9B4"/>
</form>
</template>

<script>
export default {
    name: 'EditFrage',
    props: {
        frage: Object
    },
    data(){
        return {
            id: this.frage.id,
            titel: this.frage.titel,
            text: this.frage.text,
            punkte: this.frage.punkte
        }
    },
    methods:{
        onSubmit(e) {
            e.preventDefault()
            
            if(!this.titel){
                alert('Bitte geben sie einen Titel an.')
                return
            }
            if(!this.text){
                alert('Bitte geben sie einen Fragentext an.')
                return
            }
            const newFrage = {
                id: this.id,
                titel: this.titel,
                text: this.text,
                punkte: this.punkte
            }

            this.$emit('edit-frage', newFrage)
        }
    }
}
</script>

<style scoped>
.edit-form {
margin-bottom: 40px;
}\sql\sql_count_avg_sum.asp
.form-control {
margin: 20px 0;
}
.form-control label {
display: block;
}
.form-control input {
width: 100%;
height: 40px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control textarea {
width: 100%;
height: 80px;
margin: 5px;
padding: 3px 7px;
font-size: 17px;
}
.form-control-check {
display: flex;
align-items: center;
justify-content: space-between;
}
.form-control-check label {
flex: 1;
}
.form-control-check input {
flex: 2;
height: 20px;
}
</style>