<!--
    This is kind of left over from earlier when buttons had extra functonality.
    Could easily be removed and replaced by the standard button in the entire project.
-->

<template>
    <button @click="onClick()" :style="{background: color, color: textcolor}" class="btn">{{text}}</button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String,
        color: String,
        textcolor: String
    },
    methods: {
        onClick() {
            this.$emit('button-clicked')
        }
    }
}
</script>